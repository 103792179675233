<template>
    <div>
        <b-card class="bg-blur">
            <h2>
                Kyc Verification - {{ type }}
            </h2>
            <div v-if="kycInfo.id && kycInfo.type == type">
                <Empty text="You have one request in progress, we will notify you by email" />
            </div>
            <div class="row text-left mt-5" v-else>
                <div class="col-12 col-md-6 mt-4">
                    <span>Identification Number</span>
                    <b-form-input placeholder="1298123" v-model="form.document" />
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <span>Country Residence</span>
                    <country-select className="form-control" v-model="form.country" blackList="US" />
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <span>City of residence</span>
                    <b-form-input placeholder="New York" v-model="form.city" />
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <span>Address of residence</span>
                    <b-form-input placeholder="Fith Avenue" v-model="form.address" />
                </div>
                <div class="col-12 mt-4">
                    <span>Document image</span>
                    <input
                        type="file"
                        class="form-control rounded-xs"
                        ref="image"
                        placeholder="Your Password"
                        @change="changeFiles"
                    />
                </div>
                <div class="col-12 text-right mt-3">
                    <ButtonAction :loading="loading" lg="true" loadingTx="Sending verification" title="Send verification" @click="onSubmit"/>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ['type'],
    data () {
        return {
            loading: false,
            form: {
                document: null,
                country: null,
                city: null,
                address: null,
                image: null,
                type: null
            },
            kycInfo: {}
        }
    },
    created () {
        this.form.type = this.type
        this.getVerificateKyc().then(response => {
            this.kycInfo = response
        })
    },
    methods: {
        ...mapActions('auth', ['verificateKyc', 'getUserInfo', 'getVerificateKyc']),
        changeFiles(){
            this.form.image = this.$refs.image.files[0]
        },
        onSubmit() {
            this.loading = true

            const formData = new FormData()
            formData.append('document', this.form.document)
            formData.append('country', this.form.country)
            formData.append('city', this.form.city)
            formData.append('address', this.form.address)
            formData.append('image', this.form.image)
            formData.append('type', this.form.type)

            this.verificateKyc(formData).then(() => {
                this.getUserInfo()
                this.getVerificateKyc().then(response => {
                    this.kycInfo = response
                })
                openNotification()
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    }
}
</script>
<style>
    .img-user img{
        border-radius: 50%;
        width: 100px !important;
        height: 100px !important;
    }
</style>
