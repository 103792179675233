<template>
    <div>
        <b-card class="bg-blur">
            <h2>
                Personal Information
            </h2>
            <div v-if="update == false">
                <div class="row mt-5">
                <div class="col-6 col-sm-2 img-user">
                    <img :src="user.image " class="w-100" alt="">
                </div>
                <div class="col-6 col-sm-2 pt-2">
                    <span>Change image</span>
                    <input
                        type="file"
                        class="form-control rounded-xs"
                        ref="image"
                        placeholder="Your Password"
                        @change="changeFiles"
                    />
                </div>
            </div>
            <div class="row text-left mt-5">
                <h4 class="mb-3">
                    Edit Personal Information
                </h4>
                <div class="col-12 col-sm-6 mt-3">
                    <span>Username</span>
                    <b-form-input placeholder="Juan José" v-model="user.username" />
                </div>
                <div class="col-12 col-sm-6 mt-3">
                    <span>Names</span>
                    <b-form-input placeholder="Juan José" v-model="user.names" />
                </div>
                <div class="col-12 mt-3">
                    <span>Email</span>
                    <b-form-input placeholder="test@as.com" v-model="user.email" />
                </div>
                <div class="col-12 col-sm-6 mt-3">
                    <span>Country</span>
                    <b-form-select v-model="user.country_uuid">
                        <b-form-select-option :value="country.value" v-for="country in countries">
                            {{country.name}}
                        </b-form-select-option>
                    </b-form-select>
                </div>
                <div class="col-12 col-sm-6 mt-3">
                    <span>Number phone</span>
                    <b-form-input placeholder="124123" v-model="user.phone" />
                </div>
                <div class="col-12 mt-4">
                    <span>Wallet de retiro</span>
                    <b-form-input placeholder="124123" v-model="user.wallet" />
                </div>
                <div class="col-12 col-sm-6 mt-3" v-if="user.is_signal == 1">
                    <span>Signal Price</span>
                    <b-form-input placeholder="124123" v-model="user.signal_price" />
                </div>
                <div class="col-12 text-right mt-3">
                    <ButtonAction :loading="loading" lg="true" loadingTx="Uploading info" title="Update info" @click="update = true"/>
                </div>
            </div>
            </div>
            <div v-else>
                <Asci
                    @validated="enableUpdate"
                    v-if="user.is_asci_enable == 1"
                    @cancel="disableUpdate"
                    :main="false"
                />
                <div class="data__actions" v-else>
                    <b-alert class="w-100 text-center" variant="primary" show>
                     <div>Segundo factor de seguridad deshabilitado, por favor activalo primero</div>
                    </b-alert>
                </div>
                </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Asci from '../../components/security/Asci.vue'
export default {
    components: {
        Asci
    },
    data () {
        return {
            update: false,
            loading: false,
            countries: null
        }
    },
    created () {
        this.loadCountries()
    },
    methods: {
        ...mapActions('auth', ['updateUser', 'getUserInfo']),
        ...mapActions('util', ['getCountries']),
        changeFiles(){
            this.user.image = this.$refs.image.files[0]
        },
        loadCountries () {
            this.getCountries().then(response => {
                this.countries = response
            })
        },
        onSubmit() {
            this.loading = true

            const formData = new FormData()
            formData.append('username', this.user.username)
            formData.append('file', this.user.image)
            formData.append('names', this.user.names)
            formData.append('email', this.user.email)
            formData.append('country_uuid', this.user.country_uuid)
            formData.append('phone', this.user.phone)
            formData.append('signal_price', this.user.signal_price)
            formData.append('wallet', this.user.wallet)

            this.updateUser({data: formData, id: this.user.id}).then(() => {
                this.getUserInfo()
                openNotification()
                this.loading = false
                this.update = false
            })
        },
        enableUpdate() {
            this.onSubmit()
        },
        disableUpdate() {
            this.update = false;
        },
    },
    computed: {
        ...mapState('auth', ['user'])
    }
}
</script>
<style>
    .img-user img{
        border-radius: 50%;
        width: 100px !important;
        height: 100px !important;
    }
</style>
