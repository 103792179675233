<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-3">
                <b-card class="bg-blur">
                    <h4 class="mb-4">Update information</h4>
                    <b-button variant="outline-primary" class="w-100" @click="tab = 1">
                        Personal Information
                    </b-button>
                    <b-button variant="outline-primary" class="w-100 mt-3" @click="tab = 2">
                        Security
                    </b-button>
                    <b-button variant="outline-primary" class="w-100 mt-3" @click="tab = 5">
                        Doble factor
                    </b-button>
                    <!-- <b-button variant="outline-primary" class="w-100 mt-3" @click="tab = 3">
                        KYC Teacher request
                    </b-button>
                    <b-button variant="outline-primary" class="w-100 mt-3" @click="tab = 4">
                        KYC Signal request
                    </b-button> -->
                </b-card>
            </div>
            <div class="col-12 col-sm-9">
                <Profile v-if="tab == 1"/>
                <Password v-else-if="tab == 2"/>
                <FormKyc type="Teacher" v-else-if="tab == 3"/>
                <FormKyc type="Signals" v-else-if="tab == 4"/>
                <b-card class="bg-full" v-else-if="tab == 5">
                    <Asci :main="true" />
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
import Password from './Password.vue'
import Profile from './Profile.vue'
import FormKyc from './FormKyc.vue'
import Asci from '@/components/security/Asci.vue'
export default {
    components: {
    Password,
    Profile,
    FormKyc,
    Asci
},
    data () {
        return {
            tab: 1
        }
    }
}
</script>