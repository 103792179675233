<template>
  <section class="auth">
    <h2 class="titleData">Doble Factor</h2>
    <div v-if="(main == true && user.is_asci_enable == 0) ||
      (main == false && user.is_asci_enable == 1)
      ">
      <section class="auth__container">
        <article class="auth__container__text"></article>
        <article class="auth__container__qr">
          <div class="auth__container__qr--img" v-if="user.is_asci_enable == 0">
            <qrcode-vue v-if="asci" style="margin: auto" :value="asci" size="200" level="M" />
          </div>
          <div class="auth__container--imgs" v-if="user.is_asci_enable == false">
            <a class="click" href="https://apps.apple.com/es/app/google-authenticator/id388497605" target="_blank">
              <img src="/assets/images/apple.jpg" alt="" />
            </a>
            <a class="click"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es_CO&gl=US"
              target="_blank">
              <img src="/assets/images/andrioid.jpg" alt="" />
            </a>
          </div>
          <h4>Clave privada</h4>
          <Copy v-if="asci && user.is_asci_enable == 0" :address="splited(asci)"></Copy>
          <div class="auth__container--google">
            <h5>Google</h5>
            <b-form-input v-model="form.code" placeholder="Code"></b-form-input>
          </div>
        </article>
      </section>
      <section class="auth__actions mt-3">
        <ButtonAction :loading="false" title="Cancelar" @click="$emit('cancel')" variant="outline-primary"></ButtonAction>
        <ButtonAction :loading="false" title="Validar" @click="onSubmit()"></ButtonAction>
      </section>
    </div>
    <div v-else class="text-center">
      <img src="@/assets/images/logo.png" style="max-width: 30%" alt="" />
      <h3 v-if="user.is_asci_enable == 1">
        Habilitado
      </h3>
      <h3 v-if="user.is_asci_enable == 0">
        Deshabilitado
      </h3>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import QrcodeVue from "qrcode.vue";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    main: {
      default: false,
    },
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      form: {
        code: null,
      },
    };
  },
  setup() {
    const showSuccesUser = ref(false);
    return {
      showSuccesUser,
    };
  },
  created() {
    this.showAsci();
  },
  methods: {
    ...mapActions("security", ["showAsci", "validateAsci"]),
    ...mapActions("auth", ["getUserInfo"]),
    onSubmit() {
      this.validateAsci(this.form).then(() => {
        this.getUserInfo();
        this.$emit("validated");
      });
    },
    splited(text) {
      if (text) {
        let key = text.split("=", 2);
        return key[1];
      }
    },
  },
  computed: {
    ...mapState("security", ["asci"]),
    ...mapState("auth", ["user"]),
  },
};
</script>

<style scoped>
.auth {
	 cursor: default;
	 color: white;
	 display: flex;
	 flex-direction: column;
	 gap: 24px;
}
 .auth--Title {
	 margin-top: 48px;
	 margin-bottom: 24px;
	 font-size: 26px;
	 font-weight: 400;
	 line-height: 32px;
	 text-align: left;
}
 .auth__container--google {
	 padding: 8px 16px;
	 border: 1px solid #dde4ed;
	 border-radius: 8px;
}
 .auth__container--imgs {
	 display: flex;
	 justify-content: center;
	 flex-wrap: wrap;
	 gap: 24px;
}
 .auth__container__text p {
	 margin-bottom: 25px;
}
 .auth__container__text p.grey {
	 color: #fff;
}
 .auth__container__text div {
	 display: flex;
	 margin-bottom: 25px;
}
 @media (max-width: 1024px) {
	 .auth__container__text div {
		 flex-wrap: wrap;
	}
}
 .auth__container__text div img {
	 margin-right: 20px;
}
 @media (max-width: 1024px) {
	 .auth__container__text div img {
		 margin-bottom: 20px;
	}
}
 .auth__container__qr {
	 display: flex;
	 flex-direction: column;
}
 .auth__container__qr--img {
	 padding: 20px;
	 margin: 0 auto;
	 margin-bottom: 25px;
	 border-radius: 8px;
	 background: #f6f8fa;
}
 .auth__container__qr--img img {
	 margin: 0 auto;
}
 .auth__container__qr .copy {
	 margin-bottom: 25px;
}
 .auth__container__qr h4 {
	 font-weight: 300;
	 font-size: 16px;
	 color: #fff;
	 margin-bottom: 10px;
}
 .auth__container__qr h5 {
	 font-size: 16px;
}
 .auth__actions {
	 display: flex;
	 justify-content: end;
}
 .auth__actions button {
	 width: auto;
	 margin-left: 20px;
}
 
</style>
